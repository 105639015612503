<template>
  <div>
    <b-row>
      <b-col class="d-flex justify-content-between pb-1 align-items-center">
        <div>
          <h1 class="font-weight-bolder">
            {{ expense.name }}
          </h1>
          <h4
            v-if="isMaterial"
            class="text-secondary"
          >
            {{ expense.description }}
          </h4>
        </div>
      </b-col>
      <b-col>
        <b-row>
          <b-col class="text-right" />
          <b-col sm="5">
            <income-total-card
              :label="'totalExcomes'"
              :total-income="total"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        sm="12"
        class="mb-2"
      >
        <div class="d-flex align-items-center">
          <span class="mr-2">{{ $t('show') }} </span>
          <b-form-select
            :value="expensesPagination.per_page"
            style="width:100px"
            :options="perPageOptions"
            @change="changePerPageValue($event)"
          />
        </div>
      </b-col>
      <b-col sm="12">
        <b-row>
          <b-col
            sm="6"
            lg="3"
          >
            <b-form-group :label="$t('fromDate')">
              <b-form-datepicker
                v-model="query.dates.begin"
                reset-button
                @input="filterExpenses"
              />
            </b-form-group>
          </b-col>
          <b-col
            sm="6"
            lg="3"
          >
            <b-form-group :label="$t('toDate')">
              <b-form-datepicker
                v-model="query.dates.end"
                reset-button
                @input="filterExpenses"
              />
            </b-form-group>
          </b-col>
          <b-col
            sm="6"
            lg="3"
          >
            <b-form-group :label="$t('status')">
              <b-form-select
                v-model="query.status"
                :options="expenseStatusOptions"
                @change="filterExpenses"
              />
            </b-form-group>
          </b-col>
          <b-col
            sm="6"
            lg="3"
          >
            <b-form-group :label="$t('supplier')">
              <b-form-input
                v-model="query.supplier"
                autocomplete="off"
                type="text"
                @change="filterExpenses"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col>
        <expenses-table
          :is-material="isMaterial"
          :expenses="expenses"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col class="d-flex justify-content-end">
        <b-pagination
          :value="expensesPagination.page"
          :total-rows="expensesPagination.total_objects"
          :per-page="expensesPagination.per_page"
          aria-controls="my-table"
          @change="nextPage($event)"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ExpensesTable from '@/components/expenses/ExpensesTable.vue'
import IncomeTotalCard from '@/components/income/IncomeTotalCard.vue'

export default {
  components: {
    ExpensesTable,
    IncomeTotalCard,
  },
  data() {
    return {
      loading: false,
      perPageOptions: [
        { value: 10, text: '10' },
        { value: 20, text: '20' },
      ],
      expenseStatusOptions: [
        { text: this.$t('paid'), value: 'paid' },
        { text: this.$t('credit'), value: 'credit' },
        { text: this.$t('expired'), value: 'expired' },
        { text: this.$t('received'), value: 'received' },
      ],
      query: {
        dates: {},
        status: null,
        supplier: null,
      },
      expense: { name: '', description: '' },
    }
  },
  computed: {
    ...mapGetters('projects', ['project']),
    ...mapGetters('expenses', [
      'expenses',
      'expensesPagination',
      'expensesTotal',
    ]),
    total() {
      return `$${this.$options.filters.money(this.expensesTotal)}`
    },
    isMaterial() {
      return this.expenses[0]?.expense_type_catalog.name === 'Material'
    },
  },

  mounted() {
    this.fetchExpenses({
      projectId: this.$route.params.projectId,
      groupId: this.$route.params.groupedExpenseId,
      pagination: { page: 1, per_page: 10 },
    }).then(response => {
      this.expense.name = response.data[0].name
      this.expense.description = response.data[0].description
    })
  },
  methods: {
    ...mapMutations('expenses', {
      changePagination: 'CHANGE_PAGINATION',
    }),
    ...mapActions('expenses', ['fetchExpenses']),
    changePerPageValue(value) {
      this.changePagination({ field: 'per_page', value })
      this.fetchExpenses({
        projectId: this.$route.params.projectId,
        groupId: this.$route.params.groupedExpenseId,
        pagination: this.expensesPagination,
      })
    },
    nextPage(value) {
      this.changePagination({ field: 'page', value })
      this.fetchExpenses({
        projectId: this.$route.params.projectId,
        groupId: this.$route.params.groupedExpenseId,
        pagination: this.expensesPagination,
      })
    },
    filterExpenses() {
      let dates = {}
      if (this.query.dates.begin?.length) {
        dates.start_date = this.query.dates.begin
      }
      if (this.query.dates.end?.length) {
        dates.end_date = this.query.dates.end
      }
      if (!Object.keys(dates).length) {
        dates = null
      }
      this.fetchExpenses({
        projectId: this.$route.params.projectId,
        groupId: this.$route.params.groupedExpenseId,
        supplier: this.query.supplier,
        status: this.query.status,
        dates,
        pagination: this.expensesPagination,
      })
    },
  },
}
</script>

<style></style>
